import type { FC } from 'react';
import { Theme } from '@material-ui/core';
// import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface AnimatedLogoProps {
  sx?: SxProps<Theme>;
}

// const LogoRoot = experimentalStyled('svg')();

const AnimatedLogo: FC<AnimatedLogoProps> = () => (
  <img
    width="80px"
    alt="YieldScanner Logo"
    src="/static/animated_logo.svg"
  />
);

export default AnimatedLogo;
