import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
  Link,
  Grid,
  Autocomplete
} from '@material-ui/core';
import { countries } from 'src/config';
import AnimatedLogo from 'src/components/AnimatedLogo';
import useAuth from '../../../hooks/useAuth';
// import useIsMountedRef from '../../../hooks/useIsMountedRef';

const RegisterJWT: FC = (props) => {
  // const isMountedRef = useIsMountedRef();
  const { register } = useAuth() as any;
  const { t } = useTranslation('register');

  return (
    <Formik
      initialValues={{
        email: '',
        emailConfirmation: '',
        name: '',
        password: '',
        passwordConfirmation: '',
        submit: null,
        country: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('e-mail.invalid email'))
          .max(255)
          .required(t('e-mail.e-mail is required')),
        emailConfirmation: Yup.string().oneOf(
          [Yup.ref('email'), null],
          t('e-mail confirmation.e-mail must match')
        ),
        name: Yup.string().max(255).required(t('name.name is required')),
        country: Yup.string().max(255).required(t('country.country is required')),
        password: Yup.string().min(7, t('password.password must be at least 7 characters')).max(255).required(t('password.password is required')),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          t('password confirmation.password must match')
        ),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ) => {
        try {
          setSubmitting(true);
          await register(
            values.name,
            values.email,
            values.country,
            values.password,
          );
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          if (err.code === 'DUPLICATE_ENTRY') {
            setErrors({ submit: t('user already exists') });
          } else {
            setErrors({ submit: err.message || err.code });
          }
          setSubmitting(false);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <Grid
            container
            spacing={2}
            padding={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('name.label')}
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Autocomplete
                getOptionLabel={(option): string => option.text}
                options={countries}
                onChange={(_, newValue: any) => {
                  setFieldValue('country', newValue?.value || undefined);
                }}
                renderInput={(params): JSX.Element => (
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    onChange={handleChange}
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={t('e-mail.label')}
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(
                  touched.emailConfirmation && errors.emailConfirmation
                )}
                fullWidth
                helperText={
                  touched.emailConfirmation && errors.emailConfirmation
                }
                label={t('e-mail confirmation.label')}
                margin="normal"
                name="emailConfirmation"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.emailConfirmation}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label={t('password.label')}
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(
                  touched.passwordConfirmation && errors.passwordConfirmation
                )}
                fullWidth
                helperText={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
                label={t('password confirmation.label')}
                margin="normal"
                name="passwordConfirmation"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.passwordConfirmation}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              // justify="center"
              alignItems="center"
              xs={12}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  ml: -1,
                  mt: 2,
                }}
              >
                <Typography
                  color="textSecondary"
                  variant="body2"
                  align="center"
                >
                  {t('agreement text')}
                  {' '}
                  <Link
                    color="#0DCEE6"
                    component="a"
                    href="https://www.yieldscanner.com/toc"
                    target="_blank"
                  >
                    {t('terms and conditions')}
                  </Link>
                </Typography>
              </Box>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 2 }}>
                {isSubmitting ? <AnimatedLogo />
                  : (
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{
                        color: 'black',
                        backgroundColor: '#F3C31A',
                        marginTop: 16,
                        marginBottom: 14,
                      }}
                    >
                      {t('create').toUpperCase()}
                    </Button>
                  )}
              </Box>
            </Grid>

          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default RegisterJWT;
