import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface LogoWithNameProps {
  sx?: SxProps<Theme>;
}

const LogoWithWhiteNameRoot = experimentalStyled('svg')();

const LogoWithWhiteName: FC<LogoWithNameProps> = (props) => (
  <LogoWithWhiteNameRoot
    width="35.578"
    height="35.578"
    viewBox="0 0 31.578 31.578"
    version="1.1"
    {...props}
  >
    <title>Coindex</title>
    <g
      id="Group_311"
      data-name="Group 311"
      transform="translate(-332.993 -470.493)"
    >
      <path
        id="Path_232"
        data-name="Path 232"
        d="M10.849,11.3l-.026,4.706L5.909,13.969ZM25.773,5.74l-2.054,4.966L20.313,7.378l5.46-1.638ZM19.741,7.352l-3.354,3.315L14.359,5.753l5.382,1.6Zm14.482,6.63-4.966,2.054-.052-4.771Zm-5.408-3.133L24.1,10.823l2.041-4.914Zm5.551,14.924-4.953-2.054,3.328-3.406,1.625,5.46Zm-1.612-6.032L29.44,16.387l4.927-2.028ZM26.137,34.224l-2.054-4.966,4.758-.052Zm3.133-5.408.013-4.719L34.2,26.137l-4.927,2.678ZM14.333,34.367,16.4,29.414l3.406,3.328-5.473,1.625Zm6.045-1.612,3.341-3.315,2.041,4.927ZM5.9,26.137l4.953-2.054.065,4.758L5.9,26.137ZM11.3,29.271l4.706.013L13.969,34.2,11.3,29.271ZM5.74,14.333,10.706,16.4,7.378,19.806,5.74,14.333Zm1.612,6.045,3.315,3.341L5.753,25.76ZM13.982,5.9l2.054,4.953-4.771.065L13.982,5.9Z"
        transform="translate(328.722 466.222)"
        fill="rgba(243,195,26,0.4)"
        fillRule="evenodd"
      />
      <path
        id="Path_233"
        data-name="Path 233"
        d="M20.392,4.61a15.827,15.827,0,0,1,5.694,1.053l-5.694,1.7L14.7,5.676A15.662,15.662,0,0,1,20.392,4.61Zm6.383,1.339a15.828,15.828,0,0,1,8.06,8.06L29.61,11.188,26.776,5.949ZM35.122,14.7a15.741,15.741,0,0,1,0,11.388l-1.7-5.694,1.7-5.694Zm-.286,12.077a15.828,15.828,0,0,1-8.06,8.06L29.61,29.61ZM26.1,35.122a15.775,15.775,0,0,1-11.4,0l5.694-1.7Zm-12.077-.286a15.9,15.9,0,0,1-8.073-8.06l5.239,2.834,2.834,5.226ZM5.676,26.1A15.68,15.68,0,0,1,4.61,20.392,15.933,15.933,0,0,1,5.663,14.7l1.7,5.694Zm.273-12.09a15.925,15.925,0,0,1,8.06-8.06l-2.821,5.239L5.949,14.009Z"
        transform="translate(328.383 465.883)"
        fill="#f3c31a"
        fillRule="evenodd"
      />
    </g>
  </LogoWithWhiteNameRoot>
);

export default LogoWithWhiteName;
