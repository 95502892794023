import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface DepositIconProps {
  sx?: SxProps<Theme>;
}

const DepositIconRoot = experimentalStyled('svg')();

const DepositIcon: FC<DepositIconProps> = (props) => (
  <DepositIconRoot
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    version="1.1"
    {...props}
  >
    <title>Deposits</title>
    <g fill="#ebc548">
      <path d="M6 26h16V6H6v20zm0 16h16V30H6v12zm20 0h16V22H26v20zm0-36v12h16V6H26z" />
    </g>
  </DepositIconRoot>
);

export default DepositIcon;
