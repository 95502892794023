import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import UserIcon from '../../icons/User';

const AccountPopover: FC = () => {
  const { t } = useTranslation('navbar');
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();

  const searchUserData = user.currentPlan.currentSearchStatus
    ?.split('/')
    .map((item) => parseFloat(item));

  let currentSimulations = 0;
  let planSimulationsLimit = 5;
  if (searchUserData && searchUserData.length >= 2) {
    [currentSimulations, planSimulationsLimit] = searchUserData;
  }
  if (currentSimulations > planSimulationsLimit) {
    currentSimulations = planSimulationsLimit;
  }

  const productStatusData = user.currentPlan.currentProductStatus
    ?.split('/')
    .map((item) => parseFloat(item));
  let currentProductsQuantity = 0;
  let planProductsLimit = 0;
  if (productStatusData && productStatusData.length >= 2) {
    [currentProductsQuantity, planProductsLimit] = productStatusData;
  }

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <AccountCircleIcon
          sx={{
            height: 32,
            width: 32,
            fill: '#F3C31A',
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {user.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            YieldScanner
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 1, p: 2, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              {t('searches limit')}
            </Typography>
            <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              {`${currentSimulations}/${planSimulationsLimit}`}
            </Typography>
          </Box>
          <LinearProgress
            value={(currentSimulations / planSimulationsLimit) * 100}
            variant="determinate"
          />
        </Box>

        <Box sx={{ mb: 1, p: 2, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              {t('products in portfolio')}
            </Typography>
            <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              {`${currentProductsQuantity}/${planProductsLimit}`}
            </Typography>
          </Box>
          <LinearProgress
            value={(currentProductsQuantity / planProductsLimit) * 100}
            variant="determinate"
          />
        </Box>

        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem
            component={RouterLink}
            to="/dashboard/account"
          >
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  My Account
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
