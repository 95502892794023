import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardContent,
  Container,
  Link,
  Typography
} from '@material-ui/core';
import LogoWithName from 'src/components/LogoWithName';
import {
  // RegisterAmplify,
  // RegisterAuth0,
  // RegisterFirebase,
  RegisterJWT
} from '../../components/authentication/register';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';

const Register: FC = () => {
  const { platform } = useAuth() as any;

  const { t } = useTranslation('register');

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('register | yieldscanner')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Card>
            <Box
              sx={{
                mb: 2,
                mt: 4,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <RouterLink to="/">
                <LogoWithName
                  sx={{
                    width: 200
                  }}
                />
              </RouterLink>
            </Box>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >

                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                  align="center"
                >
                  {t('create your account')}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {t('already a user ?')}
                  {' '}
                  <Link
                    color="#0DCEE6"
                    component="a"
                    href="/dashboard"
                  >
                    {t('login')}
                  </Link>
                </Typography>

              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                {platform === 'JWT' && <RegisterJWT />}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Register;
