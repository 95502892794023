import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const Research = Loadable(lazy(() => import('./pages/dashboard/Research')));
const FindAndCompare = Loadable(lazy(() => import('./pages/dashboard/FindAndCompare')));
const FindStocks = Loadable(lazy(() => import('./pages/dashboard/FindStocks')));
const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const MyDeposits = Loadable(lazy(() => import('./pages/dashboard/MyDeposits')));
const MyCryptoAssets = Loadable(lazy(() => import('./pages/dashboard/MyCryptoAssets')));
const MyEquityAssets = Loadable(lazy(() => import('./pages/dashboard/MyEquityAssets')));
const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));

// Projects pages

const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/Plans')));
const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));

const CoindexProfile = Loadable(lazy(() => import('./pages/dashboard/CoindexProfile')));

// Other pages

const Contact = Loadable(lazy(() => import('./pages/Contact')));

const routes: PartialRouteObject[] = [
  {
    path: 'contact',
    element: <Contact />
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/overview',
        element: <Overview />
      },
      {
        path: '/my-deposits',
        element: <MyDeposits />
      },
      {
        path: '/my-crypto-assets',
        element: <MyCryptoAssets />
      },
      {
        path: '/my-equity-assets',
        element: <MyEquityAssets />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'customers',
        children: [
          {
            path: '/',
            element: <CustomerList />
          },
          {
            path: ':customerId',
            element: <CustomerDetails />
          },
          {
            path: ':customerId/edit',
            element: <CustomerEdit />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '/',
            element: <OrderList />
          },
          {
            path: ':orderId',
            element: <OrderDetails />
          }
        ]
      },
      {
        path: 'find-and-compare',
        element: <FindAndCompare />
      },
      {
        path: 'find-stocks',
        element: <FindStocks />
      },
      {
        path: 'research',
        element: <Research />
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: <ProductList />
          },
          {
            path: 'new',
            element: <ProductCreate />
          }
        ]
      },
      {
        path: 'plans',
        children: [
          {
            path: '/',
            element: <ProjectCreate />
          },
          {
            path: 'browse',
            element: <ProjectBrowse />
          },
          {
            path: ':projectId',
            element: <ProjectDetails />
          }
        ]
      },
      {
        path: 'coindex',
        children: [
          {
            path: '/',
            element: <CoindexProfile />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '*',
        element: <Overview />
      },
    ]
  }
];

export default routes;
