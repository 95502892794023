import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface DepositIconProps {
  sx?: SxProps<Theme>;
}

const DepositIconRoot = experimentalStyled('svg')();

const DepositIcon: FC<DepositIconProps> = (props) => (
  <DepositIconRoot
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    version="1.1"
    {...props}
  >
    <title>Deposits</title>
    <g fill="#ebc548">
      <path d="M7 36.99l12-12.03 8 8 17-19.12-2.82-2.83L27 26.96l-8-8L4 33.99z" />
    </g>
  </DepositIconRoot>
);

export default DepositIcon;
