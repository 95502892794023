import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Typography, Hidden, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { colors } from 'src/theme/constants';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useTheme } from '@material-ui/core/styles';
import Scrollbar from '../Scrollbar';
import CoindexLogo from '../CoindexLogo';
import DepositIcon from '../DepositIcon';
import DasboardIcon from '../DasboardIcon';
import EquityIcon from '../EquityIcon';
import BlockchainIcon from '../BlockchainIcon';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: 'dashboard',
    path: '/dashboard/overview',
    icon: <DasboardIcon />
  },
  {
    title: 'research',
    path: '/dashboard/research',
    icon: <FindInPageIcon
      style={{ fill: colors.yellow, fontSize: 32 }}
    />
  },
  {
    title: 'my deposits',
    path: '/dashboard/my-deposits',
    icon: <DepositIcon />
  },
  {
    title: 'my crypto assets',
    path: '/dashboard/my-crypto-assets',
    icon: <BlockchainIcon />
  },
  {
    title: 'my equity assets',
    path: '/dashboard/my-equity-assets',
    icon: <EquityIcon />
  },
  {
    title: 'coindex',
    path: '/dashboard/coindex',
    icon: <CoindexLogo />
  },
  {
    title: 'my account',
    path: '/dashboard/account',
    icon: <AccountCircleIcon
      style={{ fill: colors.yellow, fontSize: 32 }}
    />
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { t } = useTranslation('navbar');
  const theme = useTheme();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const renderSections = () => (
    sections.map((section) => (
      <RouterLink
        key={section.title}
        to={section.path}
        style={{ textDecoration: 'none' }}
      >
        <Box
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            backgroundColor: location.pathname === section.path ? 'rgba(255,255,255,0.13)' : undefined,
            transition: 'background-color 0.5s ease',
            ':hover': {
              backgroundColor: 'rgba(255,255,255,0.13)',
            },
          }}
        >
          {section.icon}
          <Typography
            color="white"
            align="center"
            marginTop={1}
          >
            {t(section.title)}
          </Typography>
        </Box>
      </RouterLink>
    ))
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        mt: 8,
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        {renderSections()}
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={openMobile}
          onClose={onMobileClose}
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.mode === 'light' ? 'primary.main' : 'background.paper',
              height: '100%',
              width: 210,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.mode === 'light' ? 'primary.main' : 'background.paper',
              height: '100%',
              width: 210,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
