import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { apiConfig } from 'src/config';
import axios from '../lib/axios';
import type { User } from '../types/user';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  passwordRecovery: (email: string) => Promise<void>;
  passwordReset: (password: string, token: string) => Promise<void>;
  register: (
    name: string,
    email: string,
    country: string,
    password: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type PasswordRecoverAction = {
  type: 'RECOVERY_EMAIL_SENT';
};

type PasswordUpdatedAction = {
  type: 'PASSWORD_UPDATED';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | PasswordRecoverAction
  | PasswordUpdatedAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  register: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          setSession(accessToken);

          const response = await axios.get<{ results: any }>(
            `${apiConfig.userApiBaseUrl}/users/me`
          );

          const user = response.data.results.object.users[0];

          if (user) {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const response = await axios.post<{
      accessToken: string;
      user: User;
      results: any;
    }>(`${apiConfig.userApiBaseUrl}/auth/login`, {
      email,
      password,
    });
    const { accessToken, user } = response.data.results.object;

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const passwordRecovery = async (email: string): Promise<void> => {
    await axios.post<{
      accessToken: string;
      user: User;
      results: any;
    }>(`${apiConfig.userApiBaseUrl}/auth/recover`, {
      email,
    });

    dispatch({
      type: 'RECOVERY_EMAIL_SENT',
    });
  };

  const passwordReset = async (
    password: string,
    token: string
  ): Promise<void> => {
    await axios.post<{
      accessToken: string;
      user: User;
      results: any;
    }>(`${apiConfig.userApiBaseUrl}/auth/reset-password?token=${token}`, {
      password,
    });

    dispatch({
      type: 'RECOVERY_EMAIL_SENT',
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    name: string,
    email: string,
    country: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post<{
      accessToken: string;
      user: User;
      code: string;
      results: any;
    }>(`${apiConfig.userApiBaseUrl}/users/new-user`, {
      name,
      email,
      country,
      password,
    });

    if (response.data.code === 'USER_CREATED') {
      const { accessToken, user } = response.data.results.object;

      window.localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: 'REGISTER',
        payload: {
          user,
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        passwordRecovery,
        passwordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
