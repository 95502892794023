import type { FC } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, FormGroup, Switch } from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import { colors } from 'src/theme/constants';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import NightsStayIcon from '@material-ui/icons/NightsStay';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingBottom: 8,
    margin: theme.spacing(1),
  },
  switchBase: {
    width: 40,
    '&$checked': {
      color: colors.yellow,
    },
    '&$checked + $track': {
      backgroundColor: 'white',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    backgroundColor: '#0C2135',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {

  },
}));

const ThemeToggler: FC = () => {
  const classes = useStyles();

  const { settings, saveSettings } = useSettings();

  return (
    <Box>
      <FormGroup>
        <Switch
          icon={<NightsStayIcon />}
          checkedIcon={<Brightness7Icon />}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            track: classes.track,
            checked: classes.checked
          }}
          checked={settings.theme === THEMES.DARK}
          onChange={() => {
            if (settings.theme === THEMES.DARK) {
              saveSettings({ ...settings, theme: THEMES.LIGHT });
            } else {
              saveSettings({ ...settings, theme: THEMES.DARK });
            }
          }}
          name="themeSelector"
        />
      </FormGroup>
    </Box>
  );
};

export default ThemeToggler;
