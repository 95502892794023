import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const LoginJWT: FC = (props) => {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuth() as any;
  const { t } = useTranslation('login');

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            email: Yup
              .string()
              .email('Must be a valid email')
              .max(255)
              .required(t('e-mail.e-mail is required')),
            password: Yup
              .string()
              .max(255)
              .required(t('password.password is required'))
          })
      }
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          await login(values.email, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          enqueueSnackbar('Invalid Credentials', {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
            variant: 'error',
          });
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <Grid
            container
            spacing={2}
            padding={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                autoFocus
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={t('e-mail.label')}
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label={t('password.label')}
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              container
              direction="column"
              alignItems="center"
              xs={12}
            >
              {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
              )}
              <Box sx={{ mt: 2 }}>
                <Button
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    color: 'black',
                    backgroundColor: '#F3C31A'
                  }}
                >
                  {t('log in').toUpperCase()}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
