export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

export const apiConfig = {
  userApiBaseUrl: process.env.REACT_APP_YIELDSCANNER_USER_API,
  stripeApiKey: process.env.REACT_APP_STRIPE_PUB_KEY,
};

export const countries = [
  { text: 'Belgium', value: 'BE' },
  { text: 'Brasil', value: 'BR' },
  { text: 'Luxembourg', value: 'LU' },
  { text: 'Germany', value: 'DE' },
  { text: 'Spain', value: 'ES' },
  { text: 'France', value: 'FR' },
  { text: 'Ireland', value: 'IE' },
  { text: 'Italy', value: 'IT' },
  { text: 'Netherlands', value: 'NL' },
  { text: 'Austria', value: 'AT' },
  { text: 'Portugal', value: 'PT' },
  { text: 'Finland', value: 'FI' },
  { text: 'Greece', value: 'GR' },
  { text: 'Slovenia', value: 'SI' },
  { text: 'Cyprus', value: 'CY' },
  { text: 'Malta', value: 'MT' },
  { text: 'Slovakia', value: 'SK' },
  { text: 'Estonia', value: 'EE' },
  { text: 'Latvia', value: 'LV' },
  { text: 'Lithuania', value: 'LT' },
  { text: 'United Kingdom', value: 'GB' },
  { text: 'United States', value: 'US' },
];

export const coins = [
  {
    id: 'bitcoin',
    symbol: 'btc',
    name: 'Bitcoin',
    image:
      'https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579',
  },
  {
    id: 'ethereum',
    symbol: 'eth',
    name: 'Ethereum',
    image:
      'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
  },
  {
    id: 'tether',
    symbol: 'usdt',
    name: 'Tether',
    image:
      'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707',
  },
  {
    id: 'binancecoin',
    symbol: 'bnb',
    name: 'Binance Coin',
    image:
      'https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png?1547034615',
  },
  {
    id: 'cardano',
    symbol: 'ada',
    name: 'Cardano',
    image:
      'https://assets.coingecko.com/coins/images/975/large/cardano.png?1547034860',
  },
  {
    id: 'dogecoin',
    symbol: 'doge',
    name: 'Dogecoin',
    image:
      'https://assets.coingecko.com/coins/images/5/large/dogecoin.png?1547792256',
  },
  {
    id: 'ripple',
    symbol: 'xrp',
    name: 'XRP',
    image:
      'https://assets.coingecko.com/coins/images/44/large/xrp-symbol-white-128.png?1605778731',
  },
  {
    id: 'usd-coin',
    symbol: 'usdc',
    name: 'USD Coin',
    image:
      'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389',
  },
  {
    id: 'polkadot',
    symbol: 'dot',
    name: 'Polkadot',
    image:
      'https://assets.coingecko.com/coins/images/12171/large/aJGBjJFU_400x400.jpg?1597804776',
  },
  {
    id: 'uniswap',
    symbol: 'uni',
    name: 'Uniswap',
    image:
      'https://assets.coingecko.com/coins/images/12504/large/uniswap-uni.png?1600306604',
  },
  {
    id: 'bitcoin-cash',
    symbol: 'bch',
    name: 'Bitcoin Cash',
    image:
      'https://assets.coingecko.com/coins/images/780/large/bitcoin-cash-circle.png?1594689492',
  },
  {
    id: 'solana',
    symbol: 'sol',
    name: 'Solana',
    image:
      'https://assets.coingecko.com/coins/images/4128/large/coinmarketcap-solana-200.png?1616489452',
  },
  {
    id: 'litecoin',
    symbol: 'ltc',
    name: 'Litecoin',
    image:
      'https://assets.coingecko.com/coins/images/2/large/litecoin.png?1547033580',
  },
  {
    id: 'internet-computer',
    symbol: 'icp',
    name: 'Internet Computer',
    image:
      'https://assets.coingecko.com/coins/images/14495/large/Internet_Computer_logo.png?1620703073',
  },
  {
    id: 'chainlink',
    symbol: 'link',
    name: 'Chainlink',
    image:
      'https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700',
  },
  {
    id: 'binance-usd',
    symbol: 'busd',
    name: 'Binance USD',
    image:
      'https://assets.coingecko.com/coins/images/9576/large/BUSD.png?1568947766',
  },
  {
    id: 'matic-network',
    symbol: 'matic',
    name: 'Polygon',
    image:
      'https://assets.coingecko.com/coins/images/4713/large/matic___polygon.jpg?1612939050',
  },
  {
    id: 'theta-token',
    symbol: 'theta',
    name: 'Theta Network',
    image:
      'https://assets.coingecko.com/coins/images/2538/large/theta-token-logo.png?1548387191',
  },
  {
    id: 'stellar',
    symbol: 'xlm',
    name: 'Stellar',
    image:
      'https://assets.coingecko.com/coins/images/100/large/Stellar_symbol_black_RGB.png?1552356157',
  },
  {
    id: 'ethereum-classic',
    symbol: 'etc',
    name: 'Ethereum Classic',
    image:
      'https://assets.coingecko.com/coins/images/453/large/ethereum-classic-logo.png?1547034169',
  },
  {
    id: 'vechain',
    symbol: 'vet',
    name: 'VeChain',
    image:
      'https://assets.coingecko.com/coins/images/1167/large/VeChain-Logo-768x725.png?1547035194',
  },
  {
    id: 'wrapped-bitcoin',
    symbol: 'wbtc',
    name: 'Wrapped Bitcoin',
    image:
      'https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1548822744',
  },
  {
    id: 'filecoin',
    symbol: 'fil',
    name: 'Filecoin',
    image:
      'https://assets.coingecko.com/coins/images/12817/large/filecoin.png?1602753933',
  },
  {
    id: 'tron',
    symbol: 'trx',
    name: 'TRON',
    image:
      'https://assets.coingecko.com/coins/images/1094/large/tron-logo.png?1547035066',
  },
  {
    id: 'eos',
    symbol: 'eos',
    name: 'EOS',
    image:
      'https://assets.coingecko.com/coins/images/738/large/eos-eos-logo.png?1547034481',
  },
  {
    id: 'monero',
    symbol: 'xmr',
    name: 'Monero',
    image:
      'https://assets.coingecko.com/coins/images/69/large/monero_logo.png?1547033729',
  },
  {
    id: 'dai',
    symbol: 'dai',
    name: 'Dai',
    image:
      'https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png?1574218774',
  },
  {
    id: 'aave',
    symbol: 'aave',
    name: 'Aave',
    image:
      'https://assets.coingecko.com/coins/images/12645/large/AAVE.png?1601374110',
  },
  {
    id: 'kusama',
    symbol: 'ksm',
    name: 'Kusama',
    image:
      'https://assets.coingecko.com/coins/images/9568/large/m4zRhP5e_400x400.jpg?1576190080',
  },
  {
    id: 'okb',
    symbol: 'okb',
    name: 'OKB',
    image:
      'https://assets.coingecko.com/coins/images/4463/large/okb_token.png?1548386209',
  },
  {
    id: 'shiba-inu',
    symbol: 'shib',
    name: 'Shiba Inu',
    image:
      'https://assets.coingecko.com/coins/images/11939/large/shiba.png?1622619446',
  },
  {
    id: 'compound-usd-coin',
    symbol: 'cusdc',
    name: 'cUSDC',
    image:
      'https://assets.coingecko.com/coins/images/9442/large/Compound_USDC.png?1567581577',
  },
  {
    id: 'neo',
    symbol: 'neo',
    name: 'NEO',
    image:
      'https://assets.coingecko.com/coins/images/480/large/NEO_512_512.png?1594357361',
  },
  {
    id: 'compound-ether',
    symbol: 'ceth',
    name: 'cETH',
    image:
      'https://assets.coingecko.com/coins/images/10643/large/ceth2.JPG?1581389598',
  },
  {
    id: 'cdai',
    symbol: 'cdai',
    name: 'cDAI',
    image:
      'https://assets.coingecko.com/coins/images/9281/large/cDAI.png?1576467585',
  },
  {
    id: 'cosmos',
    symbol: 'atom',
    name: 'Cosmos',
    image:
      'https://assets.coingecko.com/coins/images/1481/large/cosmos_hub.png?1555657960',
  },
  {
    id: 'iota',
    symbol: 'miota',
    name: 'IOTA',
    image:
      'https://assets.coingecko.com/coins/images/692/large/IOTA_Swirl.png?1604238557',
  },
  {
    id: 'bitcoin-cash-sv',
    symbol: 'bsv',
    name: 'Bitcoin SV',
    image:
      'https://assets.coingecko.com/coins/images/6799/large/BSV.png?1558947902',
  },
  {
    id: 'pancakeswap-token',
    symbol: 'cake',
    name: 'PancakeSwap',
    image:
      'https://assets.coingecko.com/coins/images/12632/large/IMG_0440.PNG?1602654093',
  },
  {
    id: 'algorand',
    symbol: 'algo',
    name: 'Algorand',
    image:
      'https://assets.coingecko.com/coins/images/4380/large/download.png?1547039725',
  },
  {
    id: 'theta-fuel',
    symbol: 'tfuel',
    name: 'Theta Fuel',
    image:
      'https://assets.coingecko.com/coins/images/8029/large/1_0YusgngOrriVg4ZYx4wOFQ.png?1553483622',
  },
  {
    id: 'crypto-com-chain',
    symbol: 'cro',
    name: 'Crypto.com Coin',
    image:
      'https://assets.coingecko.com/coins/images/7310/large/cypto.png?1547043960',
  },
  {
    id: 'celsius-degree-token',
    symbol: 'cel',
    name: 'Celsius Network',
    image:
      'https://assets.coingecko.com/coins/images/3263/large/CEL_logo.png?1609598753',
  },
  {
    id: 'maker',
    symbol: 'mkr',
    name: 'Maker',
    image:
      'https://assets.coingecko.com/coins/images/1364/large/Mark_Maker.png?1585191826',
  },
  {
    id: 'tezos',
    symbol: 'xtz',
    name: 'Tezos',
    image:
      'https://assets.coingecko.com/coins/images/976/large/Tezos-logo.png?1547034862',
  },
  {
    id: 'ftx-token',
    symbol: 'ftt',
    name: 'FTX Token',
    image:
      'https://assets.coingecko.com/coins/images/9026/large/F.png?1609051564',
  },
  {
    id: 'klay-token',
    symbol: 'klay',
    name: 'Klaytn',
    image:
      'https://assets.coingecko.com/coins/images/9672/large/CjbT82vP_400x400.jpg?1570548320',
  },
  {
    id: 'avalanche-2',
    symbol: 'AVAX',
    name: 'Avalanche',
    image:
      'https://assets.coingecko.com/coins/images/12559/large/coin-round-red.png?1604021818',
  },
  {
    id: 'thorchain',
    symbol: 'rune',
    name: 'THORChain',
    image:
      'https://assets.coingecko.com/coins/images/6595/large/RUNE.png?1614160507',
  },
  {
    id: 'amp-token',
    symbol: 'amp',
    name: 'Amp',
    image:
      'https://assets.coingecko.com/coins/images/12409/large/amp-200x200.png?1599625397',
  },
  {
    id: 'huobi-token',
    symbol: 'ht',
    name: 'Huobi Token',
    image:
      'https://assets.coingecko.com/coins/images/2822/large/huobi-token-logo.png?1547036992',
  },
  {
    id: 'bittorrent-2',
    symbol: 'btt',
    name: 'BitTorrent',
    image:
      'https://assets.coingecko.com/coins/images/7595/large/BTT_Token_Graphic.png?1555066995',
  },
  {
    id: 'terra-luna',
    symbol: 'luna',
    name: 'Terra',
    image:
      'https://assets.coingecko.com/coins/images/8284/large/luna1557227471663.png?1567147072',
  },
  {
    id: 'leo-token',
    symbol: 'leo',
    name: 'LEO Token',
    image:
      'https://assets.coingecko.com/coins/images/8418/large/leo-token.png?1558326215',
  },
  {
    id: 'safemoon',
    symbol: 'safemoon',
    name: 'SafeMoon',
    image:
      'https://assets.coingecko.com/coins/images/14362/large/174x174-white.png?1617174846',
  },
  {
    id: 'compound-governance-token',
    symbol: 'comp',
    name: 'Compound',
    image:
      'https://assets.coingecko.com/coins/images/10775/large/COMP.png?1592625425',
  },
  {
    id: 'terrausd',
    symbol: 'ust',
    name: 'TerraUSD',
    image:
      'https://assets.coingecko.com/coins/images/12681/large/UST.png?1601612407',
  },
  {
    id: 'telcoin',
    symbol: 'tel',
    name: 'Telcoin',
    image:
      'https://assets.coingecko.com/coins/images/1899/large/tel.png?1547036203',
  },
  {
    id: 'hedera-hashgraph',
    symbol: 'hbar',
    name: 'Hedera Hashgraph',
    image:
      'https://assets.coingecko.com/coins/images/3688/large/mqTDGK7Q.png?1566256777',
  },
  {
    id: 'decred',
    symbol: 'dcr',
    name: 'Decred',
    image:
      'https://assets.coingecko.com/coins/images/329/large/decred.png?1547034093',
  },
  {
    id: 'dash',
    symbol: 'dash',
    name: 'Dash',
    image:
      'https://assets.coingecko.com/coins/images/19/large/dash-logo.png?1548385930',
  },
  {
    id: 'sushi',
    symbol: 'sushi',
    name: 'Sushi',
    image:
      'https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png?1606986688',
  },
  {
    id: 'havven',
    symbol: 'snx',
    name: 'Synthetix Network Token',
    image:
      'https://assets.coingecko.com/coins/images/3406/large/SNX.png?1598631139',
  },
  {
    id: 'elrond-erd-2',
    symbol: 'egld',
    name: 'Elrond',
    image:
      'https://assets.coingecko.com/coins/images/12335/large/Elrond.png?1599132180',
  },
  {
    id: 'true-usd',
    symbol: 'tusd',
    name: 'TrueUSD',
    image:
      'https://assets.coingecko.com/coins/images/3449/large/tusd.png?1618395665',
  },
  {
    id: 'zcash',
    symbol: 'zec',
    name: 'Zcash',
    image:
      'https://assets.coingecko.com/coins/images/486/large/circle-zcash-color.png?1547034197',
  },
  {
    id: 'nem',
    symbol: 'xem',
    name: 'NEM',
    image:
      'https://assets.coingecko.com/coins/images/242/large/NEM_Logo_256x256.png?1598687029',
  },
  {
    id: 'yearn-finance',
    symbol: 'yfi',
    name: 'yearn.finance',
    image:
      'https://assets.coingecko.com/coins/images/11849/large/yfi-192x192.png?1598325330',
  },
  {
    id: 'holotoken',
    symbol: 'hot',
    name: 'Holo',
    image:
      'https://assets.coingecko.com/coins/images/3348/large/Holologo_Profile.png?1547037966',
  },
  {
    id: 'waves',
    symbol: 'waves',
    name: 'Waves',
    image:
      'https://assets.coingecko.com/coins/images/425/large/waves.png?1548386117',
  },
  {
    id: 'zilliqa',
    symbol: 'zil',
    name: 'Zilliqa',
    image:
      'https://assets.coingecko.com/coins/images/2687/large/Zilliqa-logo.png?1547036894',
  },
  {
    id: 'huobi-btc',
    symbol: 'hbtc',
    name: 'Huobi BTC',
    image:
      'https://assets.coingecko.com/coins/images/12407/large/Unknown-5.png?1599624896',
  },
  {
    id: 'chiliz',
    symbol: 'chz',
    name: 'Chiliz',
    image:
      'https://assets.coingecko.com/coins/images/8834/large/Chiliz.png?1561970540',
  },
  {
    id: 'near',
    symbol: 'near',
    name: 'Near',
    image:
      'https://assets.coingecko.com/coins/images/10365/large/near_icon.png?1601359077',
  },
  {
    id: 'enjincoin',
    symbol: 'enj',
    name: 'Enjin Coin',
    image:
      'https://assets.coingecko.com/coins/images/1102/large/enjin-coin-logo.png?1547035078',
  },
  {
    id: 'helium',
    symbol: 'hnt',
    name: 'Helium',
    image:
      'https://assets.coingecko.com/coins/images/4284/large/Helium_HNT.png?1612620071',
  },
  {
    id: 'staked-ether',
    symbol: 'steth',
    name: 'Lido Staked Ether',
    image:
      'https://assets.coingecko.com/coins/images/13442/large/steth_logo.png?1608607546',
  },
  {
    id: 'nexo',
    symbol: 'nexo',
    name: 'NEXO',
    image:
      'https://assets.coingecko.com/coins/images/3695/large/nexo.png?1548086057',
  },
  {
    id: 'bitcoin-gold',
    symbol: 'btg',
    name: 'Bitcoin Gold',
    image:
      'https://assets.coingecko.com/coins/images/1043/large/bitcoin-gold-logo.png?1547034978',
  },
  {
    id: 'paxos-standard',
    symbol: 'pax',
    name: 'Paxos Standard',
    image:
      'https://assets.coingecko.com/coins/images/6013/large/paxos_standard.png?1548386291',
  },
  {
    id: 'basic-attention-token',
    symbol: 'bat',
    name: 'Basic Attention Token',
    image:
      'https://assets.coingecko.com/coins/images/677/large/basic-attention-token.png?1547034427',
  },
  {
    id: 'fei-protocol',
    symbol: 'fei',
    name: 'Fei Protocol',
    image:
      'https://assets.coingecko.com/coins/images/14570/large/ZqsF51Re_400x400.png?1617082206',
  },
  {
    id: 'qtum',
    symbol: 'qtum',
    name: 'Qtum',
    image:
      'https://assets.coingecko.com/coins/images/684/large/qtum.png?1547034438',
  },
  {
    id: 'blockstack',
    symbol: 'stx',
    name: 'Stacks',
    image:
      'https://assets.coingecko.com/coins/images/2069/large/Stacks_logo_full.png?1604112510',
  },
  {
    id: 'zencash',
    symbol: 'zen',
    name: 'Horizen',
    image:
      'https://assets.coingecko.com/coins/images/691/large/horizen.png?1555052241',
  },
  {
    id: 'mdex',
    symbol: 'mdx',
    name: 'Mdex',
    image:
      'https://assets.coingecko.com/coins/images/13775/large/mdex.png?1611739676',
  },
  {
    id: 'decentraland',
    symbol: 'mana',
    name: 'Decentraland',
    image:
      'https://assets.coingecko.com/coins/images/878/large/decentraland-mana.png?1550108745',
  },
  {
    id: 'nano',
    symbol: 'nano',
    name: 'Nano',
    image:
      'https://assets.coingecko.com/coins/images/756/large/nano-coin-logo.png?1547034501',
  },
  {
    id: 'pirate-chain',
    symbol: 'arrr',
    name: 'Pirate Chain',
    image:
      'https://assets.coingecko.com/coins/images/6905/large/Pirate_Chain.png?1560913844',
  },
  {
    id: 'digibyte',
    symbol: 'dgb',
    name: 'DigiByte',
    image:
      'https://assets.coingecko.com/coins/images/63/large/digibyte.png?1547033717',
  },
  {
    id: 'harmony',
    symbol: 'one',
    name: 'Harmony',
    image:
      'https://assets.coingecko.com/coins/images/4344/large/Y88JAze.png?1565065793',
  },
  {
    id: 'the-graph',
    symbol: 'grt',
    name: 'The Graph',
    image:
      'https://assets.coingecko.com/coins/images/13397/large/Graph_Token.png?1608145566',
  },
  {
    id: 'bancor',
    symbol: 'bnt',
    name: 'Bancor Network Token',
    image:
      'https://assets.coingecko.com/coins/images/736/large/bancor.png?1547034477',
  },
  {
    id: 'ontology',
    symbol: 'ont',
    name: 'Ontology',
    image:
      'https://assets.coingecko.com/coins/images/3447/large/ONT.png?1583481820',
  },
  {
    id: 'husd',
    symbol: 'husd',
    name: 'HUSD',
    image:
      'https://assets.coingecko.com/coins/images/9567/large/HUSD.jpg?1568889385',
  },
  {
    id: 'curve-dao-token',
    symbol: 'crv',
    name: 'Curve DAO Token',
    image:
      'https://assets.coingecko.com/coins/images/12124/large/Curve.png?1597369484',
  },
  {
    id: '0x',
    symbol: 'zrx',
    name: '0x',
    image:
      'https://assets.coingecko.com/coins/images/863/large/0x.png?1547034672',
  },
  {
    id: 'uma',
    symbol: 'uma',
    name: 'UMA',
    image:
      'https://assets.coingecko.com/coins/images/10951/large/UMA.png?1586307916',
  },
  {
    id: 'siacoin',
    symbol: 'sc',
    name: 'Siacoin',
    image:
      'https://assets.coingecko.com/coins/images/289/large/siacoin.png?1548386465',
  },
  {
    id: 'bakerytoken',
    symbol: 'bake',
    name: 'BakerySwap',
    image:
      'https://assets.coingecko.com/coins/images/12588/large/bakerytoken_logo.jpg?1600940032',
  },
  {
    id: 'arweave',
    symbol: 'ar',
    name: 'Arweave',
    image:
      'https://assets.coingecko.com/coins/images/4343/large/oRt6SiEN_400x400.jpg?1591059616',
  },
  {
    id: 'liquity-usd',
    symbol: 'lusd',
    name: 'Liquity USD',
    image:
      'https://assets.coingecko.com/coins/images/14666/large/Group_3.png?1617631327',
  },
  {
    id: 'fantom',
    symbol: 'ftm',
    name: 'Fantom',
    image:
      'https://assets.coingecko.com/coins/images/4001/large/Fantom.png?1558015016',
  },
  {
    id: 'bitcoin-diamond',
    symbol: 'bcd',
    name: 'Bitcoin Diamond',
    image:
      'https://assets.coingecko.com/coins/images/1254/large/bitcoin-diamond.png?1547035280',
  },
  {
    id: 'omisego',
    symbol: 'omg',
    name: 'OMG Network',
    image:
      'https://assets.coingecko.com/coins/images/776/large/OMG_Network.jpg?1591167168',
  },
  {
    id: 'xsushi',
    symbol: 'xsushi',
    name: 'xSUSHI',
    image:
      'https://assets.coingecko.com/coins/images/13725/large/xsushi.png?1612538526',
  },
  {
    id: 'gatechain-token',
    symbol: 'gt',
    name: 'GateToken',
    image:
      'https://assets.coingecko.com/coins/images/8183/large/gt.png?1556085624',
  },
  {
    id: 'wazirx',
    symbol: 'wrx',
    name: 'WazirX',
    image:
      'https://assets.coingecko.com/coins/images/10547/large/WazirX.png?1580834330',
  },
  {
    id: 'nxm',
    symbol: 'nxm',
    name: 'Nexus Mutual',
    image:
      'https://assets.coingecko.com/coins/images/11810/large/nexus-mutual.jpg?1594547726',
  },
  {
    id: 'ravencoin',
    symbol: 'rvn',
    name: 'Ravencoin',
    image:
      'https://assets.coingecko.com/coins/images/3412/large/ravencoin.png?1548386057',
  },
  {
    id: 'swissborg',
    symbol: 'chsb',
    name: 'SwissBorg',
    image:
      'https://assets.coingecko.com/coins/images/2117/large/YJUrRy7r_400x400.png?1589794215',
  },
  {
    id: 'iostoken',
    symbol: 'iost',
    name: 'IOST',
    image:
      'https://assets.coingecko.com/coins/images/2523/large/IOST.png?1557555183',
  },
  {
    id: 'icon',
    symbol: 'icx',
    name: 'ICON',
    image:
      'https://assets.coingecko.com/coins/images/1060/large/icon-icx-logo.png?1547035003',
  },
  {
    id: 'ankr',
    symbol: 'ankr',
    name: 'Ankr',
    image:
      'https://assets.coingecko.com/coins/images/4324/large/U85xTl2.png?1608111978',
  },
  {
    id: 'kucoin-shares',
    symbol: 'kcs',
    name: 'KuCoin Token',
    image:
      'https://assets.coingecko.com/coins/images/1047/large/sa9z79.png?1610678720',
  },
  {
    id: 'livepeer',
    symbol: 'lpt',
    name: 'Livepeer',
    image:
      'https://assets.coingecko.com/coins/images/7137/large/logo-circle-green.png?1619593365',
  },
  {
    id: 'xdce-crowd-sale',
    symbol: 'xdc',
    name: 'XDC Network',
    image:
      'https://assets.coingecko.com/coins/images/2912/large/xdc-logo.png?1589242157',
  },
  {
    id: 'compound-usdt',
    symbol: 'cusdt',
    name: 'cUSDT',
    image:
      'https://assets.coingecko.com/coins/images/11621/large/cUSDT.png?1592113270',
  },
  {
    id: 'ethos',
    symbol: 'vgx',
    name: 'Voyager Token',
    image:
      'https://assets.coingecko.com/coins/images/794/large/Voyager-vgx.png?1575693595',
  },
  {
    id: 'flow',
    symbol: 'flow',
    name: 'Flow',
    image:
      'https://assets.coingecko.com/coins/images/13446/large/flow_logo.jpg?1608631239',
  },
  {
    id: 'quant-network',
    symbol: 'qnt',
    name: 'Quant',
    image:
      'https://assets.coingecko.com/coins/images/3370/large/5ZOu7brX_400x400.jpg?1612437252',
  },
  {
    id: '1inch',
    symbol: '1inch',
    name: '1inch',
    image:
      'https://assets.coingecko.com/coins/images/13469/large/1inch-token.png?1608803028',
  },
  {
    id: 'nervos-network',
    symbol: 'ckb',
    name: 'Nervos Network',
    image:
      'https://assets.coingecko.com/coins/images/9566/large/Nervos_White.png?1608280856',
  },
  {
    id: 'verge',
    symbol: 'xvg',
    name: 'Verge',
    image:
      'https://assets.coingecko.com/coins/images/203/large/verge-symbol-color_logo.png?1561543281',
  },
  {
    id: 'hbtc-token',
    symbol: 'hbc',
    name: 'HBTC Captain Token',
    image:
      'https://assets.coingecko.com/coins/images/12387/large/hbtc.jpg?1599529861',
  },
  {
    id: 'seth',
    symbol: 'seth',
    name: 'sETH',
    image:
      'https://assets.coingecko.com/coins/images/8843/large/sETH.png?1616150207',
  },
  {
    id: 'lisk',
    symbol: 'lsk',
    name: 'Lisk',
    image:
      'https://assets.coingecko.com/coins/images/385/large/Lisk_Symbol_-_Blue.png?1573444104',
  },
  {
    id: 'mina-protocol',
    symbol: 'mina',
    name: 'Mina Protocol',
    image:
      'https://assets.coingecko.com/coins/images/15628/large/JM4_vQ34_400x400.png?1621394004',
  },
  {
    id: 'chia',
    symbol: 'xch',
    name: 'Chia',
    image:
      'https://assets.coingecko.com/coins/images/15174/large/zV5K5y9f_400x400.png?1620024414',
  },
  {
    id: 'ecomi',
    symbol: 'omi',
    name: 'ECOMI',
    image:
      'https://assets.coingecko.com/coins/images/4428/large/ECOMI.png?1557928886',
  },
  {
    id: 'wootrade-network',
    symbol: 'woo',
    name: 'Wootrade Network',
    image:
      'https://assets.coingecko.com/coins/images/12921/large/w2UiemF__400x400.jpg?1603670367',
  },
  {
    id: 'reserve-rights-token',
    symbol: 'rsr',
    name: 'Reserve Rights Token',
    image:
      'https://assets.coingecko.com/coins/images/8365/large/Reserve_Rights.png?1557737411',
  },
  {
    id: 'titanswap',
    symbol: 'titan',
    name: 'TitanSwap',
    image:
      'https://assets.coingecko.com/coins/images/12606/large/nqGlQzdz_400x400.png?1601019805',
  },
  {
    id: 'republic-protocol',
    symbol: 'ren',
    name: 'REN',
    image:
      'https://assets.coingecko.com/coins/images/3139/large/REN.png?1589985807',
  },
  {
    id: 'bitcoin-cash-abc-2',
    symbol: 'bcha',
    name: 'Bitcoin Cash ABC',
    image:
      'https://assets.coingecko.com/coins/images/13120/large/12-bitcoin-cash-square-crop-small.png?1605450785',
  },
  {
    id: 'loopring',
    symbol: 'lrc',
    name: 'Loopring',
    image:
      'https://assets.coingecko.com/coins/images/913/large/LRC.png?1572852344',
  },
  {
    id: 'oxygen',
    symbol: 'oxy',
    name: 'Oxygen',
    image:
      'https://assets.coingecko.com/coins/images/13509/large/8DjBZ79V_400x400.jpg?1609236331',
  },
  {
    id: 'pundi-x-2',
    symbol: 'pundix',
    name: 'Pundi X',
    image:
      'https://assets.coingecko.com/coins/images/14571/large/vDyefsXq_400x400.jpg?1617085003',
  },
  {
    id: 'neutrino',
    symbol: 'usdn',
    name: 'Neutrino USD',
    image:
      'https://assets.coingecko.com/coins/images/10117/large/78GWcZu.png?1600845716',
  },
  {
    id: 'ergo',
    symbol: 'erg',
    name: 'Ergo',
    image:
      'https://assets.coingecko.com/coins/images/2484/large/Ergo.png?1574682618',
  },
  {
    id: 'wink',
    symbol: 'win',
    name: 'WINkLink',
    image:
      'https://assets.coingecko.com/coins/images/9129/large/WinK.png?1564624891',
  },
  {
    id: 'renbtc',
    symbol: 'renbtc',
    name: 'renBTC',
    image:
      'https://assets.coingecko.com/coins/images/11370/large/renBTC.png?1589985711',
  },
  {
    id: 'celo',
    symbol: 'celo',
    name: 'Celo',
    image:
      'https://assets.coingecko.com/coins/images/11090/large/icon-celo-CELO-color-500.png?1592293590',
  },
  {
    id: 'dent',
    symbol: 'dent',
    name: 'Dent',
    image:
      'https://assets.coingecko.com/coins/images/1152/large/gLCEA2G.png?1604543239',
  },
  {
    id: 'feg-token',
    symbol: 'feg',
    name: 'FEG Token',
    image:
      'https://assets.coingecko.com/coins/images/13878/large/fav200.png?1619597015',
  },
  {
    id: 'skale',
    symbol: 'skl',
    name: 'SKALE',
    image:
      'https://assets.coingecko.com/coins/images/13245/large/SKALE_token_300x300.png?1606789574',
  },
  {
    id: 'tribe-2',
    symbol: 'tribe',
    name: 'Tribe',
    image:
      'https://assets.coingecko.com/coins/images/14575/large/tribe.PNG?1617487954',
  },
  {
    id: 'status',
    symbol: 'SNT',
    name: 'Status',
    image:
      'https://assets.coingecko.com/coins/images/779/large/status.png?1548610778',
  },
  {
    id: 'vethor-token',
    symbol: 'vtho',
    name: 'VeThor Token',
    image:
      'https://assets.coingecko.com/coins/images/5230/large/vethor-token.png?1548760043',
  },
  {
    id: 'pundi-x',
    symbol: 'npxs',
    name: 'Pundi X [OLD]',
    image:
      'https://assets.coingecko.com/coins/images/2170/large/pundi-x.png?1548386366',
  },
  {
    id: 'maidsafecoin',
    symbol: 'maid',
    name: 'MaidSafeCoin',
    image:
      'https://assets.coingecko.com/coins/images/80/large/maidsafecoin.png?1547033750',
  },
  {
    id: 'mirror-protocol',
    symbol: 'mir',
    name: 'Mirror Protocol',
    image:
      'https://assets.coingecko.com/coins/images/13295/large/mirror_logo_transparent.png?1611554658',
  },
  {
    id: 'asd',
    symbol: 'asd',
    name: 'AscendEx Token',
    image:
      'https://assets.coingecko.com/coins/images/5003/large/bitmax.png?1621310871',
  },
  {
    id: 'compound-uniswap',
    symbol: 'cuni',
    name: 'cUNI',
    image:
      'https://assets.coingecko.com/coins/images/12696/large/compound-uni.png?1601789718',
  },
  {
    id: 'golem',
    symbol: 'glm',
    name: 'Golem',
    image:
      'https://assets.coingecko.com/coins/images/542/large/Golem_Submark_Positive_RGB.png?1606392013',
  },
  {
    id: 'iexec-rlc',
    symbol: 'rlc',
    name: 'iExec RLC',
    image:
      'https://assets.coingecko.com/coins/images/646/large/pL1VuXm.png?1604543202',
  },
  {
    id: 'conflux-token',
    symbol: 'cfx',
    name: 'Conflux',
    image:
      'https://assets.coingecko.com/coins/images/13079/large/iXocdNMF_400x400.png?1604974835',
  },
  {
    id: 'ultra',
    symbol: 'uos',
    name: 'Ultra',
    image:
      'https://assets.coingecko.com/coins/images/4480/large/Ultra.png?1563356418',
  },
  {
    id: 'kava',
    symbol: 'kava',
    name: 'Kava.io',
    image:
      'https://assets.coingecko.com/coins/images/9761/large/Kava-icon.png?1585636197',
  },
  {
    id: 'energy-web-token',
    symbol: 'ewt',
    name: 'Energy Web Token',
    image:
      'https://assets.coingecko.com/coins/images/10886/large/R9gQTJV__400x400.png?1585604557',
  },
  {
    id: 'reef-finance',
    symbol: 'reef',
    name: 'Reef Finance',
    image:
      'https://assets.coingecko.com/coins/images/13504/large/Group_10572.png?1610534130',
  },
  {
    id: 'balancer',
    symbol: 'bal',
    name: 'Balancer',
    image:
      'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
  },
  {
    id: 'usdp',
    symbol: 'usdp',
    name: 'USDP Stablecoin',
    image:
      'https://assets.coingecko.com/coins/images/13234/large/USDP.png?1606579598',
  },
  {
    id: 'pax-gold',
    symbol: 'paxg',
    name: 'PAX Gold',
    image:
      'https://assets.coingecko.com/coins/images/9519/large/paxg.PNG?1568542565',
  },
  {
    id: 'alchemix-usd',
    symbol: 'alusd',
    name: 'Alchemix USD',
    image:
      'https://assets.coingecko.com/coins/images/14114/large/Alchemix_USD.png?1614410406',
  },
  {
    id: 'band-protocol',
    symbol: 'band',
    name: 'Band Protocol',
    image:
      'https://assets.coingecko.com/coins/images/9545/large/band-protocol.png?1568730326',
  },
  {
    id: 'gnosis',
    symbol: 'gno',
    name: 'Gnosis',
    image:
      'https://assets.coingecko.com/coins/images/662/large/logo_square_simple_300px.png?1609402668',
  },
  {
    id: 'venus',
    symbol: 'xvs',
    name: 'Venus',
    image:
      'https://assets.coingecko.com/coins/images/12677/large/venus.png?1618319812',
  },
  {
    id: 'injective-protocol',
    symbol: 'inj',
    name: 'Injective Protocol',
    image:
      'https://assets.coingecko.com/coins/images/12882/large/Injective_Icon.png?1613669548',
  },
  {
    id: 'givingtoservices',
    symbol: 'svcs',
    name: 'GivingToServices',
    image:
      'https://assets.coingecko.com/coins/images/8763/large/SVCS_Ticker_logo_.png?1611590501',
  },
  {
    id: 'iotex',
    symbol: 'iotx',
    name: 'IoTeX',
    image:
      'https://assets.coingecko.com/coins/images/3334/large/iotex-logo.png?1547037941',
  },
  {
    id: 'funfair',
    symbol: 'fun',
    name: 'FUNToken',
    image:
      'https://assets.coingecko.com/coins/images/761/large/funfair.png?1592404368',
  },
  {
    id: 'alchemix',
    symbol: 'alcx',
    name: 'Alchemix',
    image:
      'https://assets.coingecko.com/coins/images/14113/large/Alchemix.png?1614409874',
  },
  {
    id: 'ocean-protocol',
    symbol: 'ocean',
    name: 'Ocean Protocol',
    image:
      'https://assets.coingecko.com/coins/images/3687/large/ocean-protocol-logo.jpg?1547038686',
  },
  {
    id: 'numeraire',
    symbol: 'nmr',
    name: 'Numeraire',
    image:
      'https://assets.coingecko.com/coins/images/752/large/numeraire.png?1592538976',
  },
  {
    id: 'tokamak-network',
    symbol: 'ton',
    name: 'Tokamak Network',
    image:
      'https://assets.coingecko.com/coins/images/12260/large/D919x5-s_400x400.png?1598568068',
  },
  {
    id: 'raydium',
    symbol: 'ray',
    name: 'Raydium',
    image:
      'https://assets.coingecko.com/coins/images/13928/large/PSigc4ie_400x400.jpg?1612875614',
  },
  {
    id: 'stakehound-staked-ether',
    symbol: 'steth',
    name: 'StakeHound Staked Ether',
    image:
      'https://assets.coingecko.com/coins/images/13752/large/926MHi5g_400x400.png?1611542247',
  },
  {
    id: 'nusd',
    symbol: 'susd',
    name: 'sUSD',
    image:
      'https://assets.coingecko.com/coins/images/5013/large/sUSD.png?1616150765',
  },
  {
    id: 'audius',
    symbol: 'audio',
    name: 'Audius',
    image:
      'https://assets.coingecko.com/coins/images/12913/large/AudiusCoinLogo_2x.png?1603425727',
  },
  {
    id: 'tenset',
    symbol: '10set',
    name: 'Tenset',
    image:
      'https://assets.coingecko.com/coins/images/14629/large/10set.png?1617353812',
  },
  {
    id: 'uquid-coin',
    symbol: 'uqc',
    name: 'Uquid Coin',
    image:
      'https://assets.coingecko.com/coins/images/1341/large/uquid-coin.png?1548759712',
  },
  {
    id: 'wax',
    symbol: 'waxp',
    name: 'WAX',
    image:
      'https://assets.coingecko.com/coins/images/1372/large/WAX_Coin_Tickers_P_512px.png?1602812260',
  },
  {
    id: 'serum',
    symbol: 'srm',
    name: 'Serum',
    image:
      'https://assets.coingecko.com/coins/images/11970/large/serum-logo.png?1597121577',
  },
  {
    id: 'cartesi',
    symbol: 'ctsi',
    name: 'Cartesi',
    image:
      'https://assets.coingecko.com/coins/images/11038/large/cartesi.png?1592288021',
  },
  {
    id: 'klever',
    symbol: 'klv',
    name: 'Klever',
    image:
      'https://assets.coingecko.com/coins/images/12240/large/Klever_Logo_200x200.png?1598441556',
  },
  {
    id: 'btc-standard-hashrate-token',
    symbol: 'btcst',
    name: 'BTC Standard Hashrate Token',
    image:
      'https://assets.coingecko.com/coins/images/14449/large/4a3IskOf_400x400.png?1616137396',
  },
  {
    id: 'fetch-ai',
    symbol: 'fet',
    name: 'Fetch.ai',
    image:
      'https://assets.coingecko.com/coins/images/5681/large/Fetch.jpg?1572098136',
  },
  {
    id: 'ton-crystal',
    symbol: 'ton',
    name: 'TON Crystal',
    image:
      'https://assets.coingecko.com/coins/images/12783/large/96667603_115652523474502_6550262491509686272_n.png?1602885498',
  },
  {
    id: 'axie-infinity',
    symbol: 'axs',
    name: 'Axie Infinity',
    image:
      'https://assets.coingecko.com/coins/images/13029/large/axie_infinity_logo.png?1604471082',
  },
  {
    id: 'celer-network',
    symbol: 'celr',
    name: 'Celer Network',
    image:
      'https://assets.coingecko.com/coins/images/4379/large/Celr.png?1554705437',
  },
  {
    id: 'persistence',
    symbol: 'xprt',
    name: 'Persistence',
    image:
      'https://assets.coingecko.com/coins/images/14582/large/512_Light.png?1617149658',
  },
  {
    id: 'nkn',
    symbol: 'nkn',
    name: 'NKN',
    image:
      'https://assets.coingecko.com/coins/images/3375/large/nkn.png?1548329212',
  },
  {
    id: 'anchor-protocol',
    symbol: 'anc',
    name: 'Anchor Protocol',
    image:
      'https://assets.coingecko.com/coins/images/14420/large/anchor_protocol_logo.jpg?1615965420',
  },
  {
    id: 'ampleforth',
    symbol: 'ampl',
    name: 'Ampleforth',
    image:
      'https://assets.coingecko.com/coins/images/4708/large/Ampleforth.png?1561684250',
  },
  {
    id: 'orchid-protocol',
    symbol: 'oxt',
    name: 'Orchid Protocol',
    image:
      'https://assets.coingecko.com/coins/images/3916/large/download_%285%29.png?1576624060',
  },
  {
    id: 'prometeus',
    symbol: 'prom',
    name: 'Prometeus',
    image:
      'https://assets.coingecko.com/coins/images/8825/large/G2LY-Dg_.png?1591698270',
  },
  {
    id: 'rocket-pool',
    symbol: 'rpl',
    name: 'Rocket Pool',
    image:
      'https://assets.coingecko.com/coins/images/2090/large/rocket.png?1563781948',
  },
  {
    id: 'everipedia',
    symbol: 'iq',
    name: 'Everipedia',
    image:
      'https://assets.coingecko.com/coins/images/5010/large/everipedia.png?1561614871',
  },
  {
    id: 'the-sandbox',
    symbol: 'sand',
    name: 'The Sandbox',
    image:
      'https://assets.coingecko.com/coins/images/12129/large/sandbox_logo.jpg?1597397942',
  },
];
