import axios from 'axios';

const axiosInstance = axios.create();

// if (localStorage.getItem('accessToken')) {
//   axiosInstance.defaults.headers.common = {
//     "S-Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
//   }
// }

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.common = {
        Authorization: `Bearer ${token}`
      };
    }
    return config;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
